import React, { useState } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css';
import { Routes, Route, useLocation } from 'react-router-dom';
import Login from './site/pages/Login';

function App() {
	const [isAuth, setIsAuth] = useState(localStorage.getItem("isAuth"));
	const location = useLocation();

	const data = {
		isAuth,
		setIsAuth,
	};
	console.log(data);

	return (
		<>
			<Routes>
				<Route exact path='/' element={<Login />} />
				<Route path='/login' element={<Login />} />
				<Route path="*" element={<Login />} />
			</Routes>
		</>
	);
}

export default App;