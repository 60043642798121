import React from "react";
import { Cookies, useCookies } from "react-cookie";
import { Link } from 'react-router-dom';

// ================================|| Header ||================================ //

const Header = () => {
  const [cookie, removeCookie] = useCookies(['username']);
  const cookies = new Cookies();
  const user = cookies.get('username');
  const role = cookies.get('role');
  const image = cookies.get('image');
  const id = cookies.get('id');
  const logout = (e) => {
    removeCookie('username', '', { path: '/admin/' });
    removeCookie('image', '', { path: '/admin/' });
    removeCookie('id', '', { path: '/admin/' });
    removeCookie('role', '', { path: '/admin/' });
    removeCookie('roleid', '', { path: '/admin/' });
    window.location.href = "https://thebridgecode.com/";
  }

  return (
    <nav className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme" id="layout-navbar">
      <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
        <a className="nav-item nav-link px-0 me-xl-4" href="javascript:void(0)">
          <i className="bx bx-menu bx-sm"></i>
        </a>
      </div>

      <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
        <div className="navbar-nav align-items-center">
          <div className="nav-item d-flex align-items-center">
            <i className="bx bx-search fs-4 lh-0"></i>
            <input
              type="text"
              className="form-control border-0 shadow-none"
              placeholder="Search..."
              aria-label="Search..."
            />
          </div>
        </div>

        <ul className="navbar-nav flex-row align-items-center ms-auto">
          <li class="nav-item lh-1 me-3">
            Welcome {user}
          </li>

          <li className="nav-item navbar-dropdown dropdown-user dropdown ">
            <a className="nav-link dropdown-toggle hide-arrow" href="javascript:void(0);" data-bs-toggle="dropdown">
              <div className="avatar avatar-online ">
                <img src={image} alt className="w-px-40 h-auto rounded-circle " />
              </div>
            </a>
            <ul className="dropdown-menu dropdown-menu-end">
              <li>
                <a className="dropdown-item" href="#">
                  <div className="d-flex">
                    <div className="flex-shrink-0 me-3">
                      <div className="avatar avatar-online">
                        <img src={image} alt className="w-px-40 h-auto rounded-circle" />
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <span className="fw-semibold d-block">{user}</span>
                      <small class="text-muted">{role}</small>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <div className="dropdown-divider"></div>
              </li>
              <li>
                <Link to='/admin/my-profile' className="dropdown-item">
                  <i className="bx bx-user me-2"></i>
                  <span className="align-middle">My Profile</span>
                </Link>
              </li>
              <li>
                <div className="dropdown-divider"></div>
              </li>
              <li>
                <a className="dropdown-item" onClick={logout} style={{ cursor: "pointer" }}>
                  <i className="bx bx-power-off me-2"></i>
                  <span className="align-middle">Log Out</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Header;